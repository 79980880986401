import React from 'react';

import styles from './BooksWishlist.module.scss';
import { IUser, ICardBook } from "../../../../types";

import CardBook from "../../../Books/CardBook/CardBook";

interface Props {
  user: IUser,
  books: Array<ICardBook>
}

const BooksWishlist = ({user, books}: Props) => {

  return (
    <div className={`${styles.BooksWishlist}`}>
      <div className={styles.title}>
        Keep {user.firstName} reading by purchasing a book from their wish list!
      </div>

      <p>
        Below are recommended books that AdamS would like to start reading to support their read-a-thon.
      </p>

      <div className={styles.list}>
        {books.map(book =>
          <CardBook book={book}
                    externalLink={true}
                    buyButton={true}
                    key={book.id}/>
        )}
      </div>

    </div>
  );
};


export default BooksWishlist;
